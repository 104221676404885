hr{
    border-top: 1px dashed black;
}

.pos_table_content{
    font-size: 14px;
}

table td{
    padding: 0px 5px;
}

table.billableItems td{
    border-right: 1px solid black;
}