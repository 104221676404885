#react-table th {
  text-align: left;
  padding: 20px;
}

#react-table thead{
  background-color: #f2f2f2;
}

#react-table tr {
  height: 55px;
}

#react-table td {
  padding-left:10px ;
}

#react-table tr:nth-child(even){background-color: #f2f2f2;}
